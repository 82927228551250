import { buildTimeConfig } from 'config'
import Head from 'next/head'
import { PropsWithChildren } from 'react'
import { Link } from '../Link/Link'

export const LayoutWithLogo: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex min-h-screen flex-col bg-gray-50">
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <header className="px-6">
        <Link href="/" className="flex py-10">
          <img
            src="/assets/svg/logo.svg"
            alt={buildTimeConfig.internalBankName}
            className="h-6"
          />
        </Link>
      </header>
      <div className="flex flex-1 flex-col">{children}</div>
    </div>
  )
}
